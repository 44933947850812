import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "advanced"
    }}>{`Advanced`}</h1>
    <p>{`Typically, you’d use MDX through something like `}<a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Webpack, Create React App,
Next, Gatsby, or others`}</a>{`.
Most of the time, it “Just Works”.
But there are some reasons to interact with the MDX library and its internals
directly.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/api"
        }}>{`API`}</a>{`
— learn about the async and sync MDX API`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/runtime"
        }}>{`Runtime`}</a>{`
— learn how to use MDX in a runtime environment (typically discouraged)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/ast"
        }}>{`AST`}</a>{`
— learn about the intermediate abstract syntax tree`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/components"
        }}>{`Components`}</a>{`
— learn about the generated JSX`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/plugins"
        }}>{`remark and rehype plugins`}</a>{`
— learn how to inspect and change the syntax tree`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/transform-content"
        }}>{`transform content`}</a>{`
— learn how to interact with or manipulate raw MDX content`}</li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      